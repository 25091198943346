import { tResourceName } from "../common/types"
/** Action Types */
import { SIDE_RAIL_SOURCE_DATA_SET, SOURCE_DATA_BULK_UPDATED } from "./actions/actionTypes"
/** Types */
import {
    tNetworkStatusState,
    tNetworkStatusAction,
    tSourceDataAction,
    tSourceDataState,
    tResourceObject,
    tReferenceableIdsState,
    tReferenceableIdsAction,
} from "./types"
/** Utilities */
import { rowIdsMatch } from "../common/ag-grid-ts-utils"
import { getFlagEnabled } from "../getFlagValue"

// Source Data ----------------------------------------------------------------

const initialSourceDataState: tSourceDataState = {
    sourceData: {},
    lockedGridIds: [],
    gridIdCounter: 0,
    newRows: [],
}

export const sourceData = (
    state: tSourceDataState = initialSourceDataState,
    action: tSourceDataAction
): tSourceDataState => {
    let newSourceData = { ...state.sourceData }
    let newGridIdCounter = state.gridIdCounter
    let newRows: tResourceObject[] = [...state.newRows]

    switch (action.type) {
        case "SOURCE_DATA_SET":
            newSourceData = {}
            newRows = []
        // falls through
        case SIDE_RAIL_SOURCE_DATA_SET: {
            // Keep both custom-dashboards (main & side rail) sourceData
            const data = action.payload
            for (const key in data) {
                /**
                 * First TypeScript complains that key isn't a tResourceName,
                 * even though it absolutely is...
                 */
                const resourceName = key as tResourceName
                /**
                 * Then, after the type assertion, it forgets that it's a key in
                 * data (and can't possibly be undefined)
                 */
                const srcData = data[resourceName] || []

                newSourceData[resourceName] = srcData.map(row => ({ ...row, gridId: newGridIdCounter++ }))
            }
            break
        }
        case "SOURCE_DATA_PARTIAL_SET": {
            const data = action.payload
            for (const key in data) {
                const resourceName = key as tResourceName
                const srcData = data[resourceName] || []
                newSourceData[resourceName] = srcData.map(row => {
                    return { ...row, gridId: newGridIdCounter++ }
                })
            }
            break
        }
        case "SOURCE_DATA_ADDED": {
            const data = action.payload
            for (const key in data) {
                const resourceName = key as tResourceName
                const srcData = data[resourceName] as tResourceObject[]
                const rows = srcData.map(row => {
                    return { ...row, gridId: newGridIdCounter++ }
                })
                const existingRows = newSourceData[resourceName] || []
                if (rows.length > 0) {
                    newSourceData[resourceName] = [...rows, ...existingRows]
                }
            }
            break
        }
        case "SOURCE_DATA_UPDATED": {
            const data = action.payload
            const sortBy = action.sortBy
            const idAttr = action.idAttr
            for (const key in data) {
                const resourceName = key as tResourceName
                const updatedRows = data[resourceName] || []
                const existingRows = newSourceData[resourceName] || []

                newSourceData[resourceName] = existingRows.map((existingRow: tResourceObject) => {
                    const updatedRow = updatedRows.find((updatedRow: tResourceObject) =>
                        rowIdsMatch(existingRow, updatedRow, idAttr || "id")
                    )
                    if (updatedRow && !updatedRow.gridId) return { ...updatedRow, gridId: newGridIdCounter++ }
                    return updatedRow || existingRow
                })
                if (sortBy) {
                    newSourceData[resourceName] = (
                        (newSourceData[resourceName] as Record<string, any>[]) || []
                    ).sort((a, b) => (a[sortBy] < b[sortBy] ? -1 : a[sortBy] === b[sortBy] ? 0 : 1))
                }
            }
            break
        }
        case SOURCE_DATA_BULK_UPDATED: {
            const data = action.payload as Record<string, any>
            const sortBy = action.sortBy
            // key is the resource name and value is the data.
            for (const [key, value] of Object.entries(data)) {
                const resource = key as tResourceName
                const updatedData = value as Array<Record<string, any>>
                const updateDataMap = {} as Record<string, any>

                // gridId is unique for each data.
                updatedData.forEach(item => {
                    updateDataMap[item.gridId] = item
                })
                const existingRows = newSourceData[resource] || []

                // Update modified rows in sourceData.
                newSourceData[resource] = existingRows.map((row: tResourceObject) => {
                    const gridId = row.gridId
                    // Sometimes gridId can be zero, so we should account for that
                    const newRowData = getFlagEnabled("WA-7850-pt-dashboard-totals")
                        ? gridId != undefined && updateDataMap[gridId]
                        : gridId && updateDataMap[gridId]
                    return newRowData || row
                })
                if (sortBy) {
                    newSourceData[resource] = (
                        (newSourceData[resource] as Record<string, any>[]) || []
                    ).sort((a, b) => (a[sortBy] < b[sortBy] ? -1 : a[sortBy] === b[sortBy] ? 0 : 1))
                }
            }
            break
        }
        case "SOURCE_DATA_DELETED": {
            const data = action.payload
            for (const key in data) {
                const resourceName = key as tResourceName
                const srcData = data[resourceName] || []
                const existingRows = newSourceData[resourceName] || []

                newSourceData[resourceName] = existingRows.filter(
                    (row: tResourceObject) =>
                        srcData.find((updatedRow: tResourceObject) => rowIdsMatch(row, updatedRow)) === undefined
                )
            }
            break
        }
        case "LOCK_SOURCE_DATA_EDITING": {
            const { lock, gridIds } = action.payload
            const { lockedGridIds } = state
            const gridIdSet = new Set(gridIds)
            return {
                ...state,
                lockedGridIds: lock
                    ? [...lockedGridIds, ...gridIds]
                    : lockedGridIds.filter(id => !gridIdSet.has(id)),
            }
        }
        case "SSRM_DATA_ADDED": {
            const data = action.payload
            for (const key in data) {
                const resourceName = key as tResourceName
                const srcData = data[resourceName] as tResourceObject[]
                const rows = srcData.map(row => {
                    newGridIdCounter++
                    return { ...row, gridId: -1 * newGridIdCounter }
                })
                if (rows.length > 0) {
                    newRows = [...rows, ...state.newRows]
                }
            }
            break
        }
        case "SSRM_DATA_SAVED": {
            const data = action.payload
            for (const key in data) {
                const resourceName = key as tResourceName
                const srcData = data[resourceName] as tResourceObject
                newRows = newRows.map(newRow => {
                    return srcData.gridId == newRow.gridId ? { ...srcData, newRow: false } : newRow
                })
            }
            break
        }
        case "SSRM_DELETE_NEW_ROWS": {
            const data = action.payload
            for (const key in data) {
                const resourceName = key as tResourceName
                const srcData = data[resourceName] || []
                newRows = newRows.filter(
                    newRow => !srcData.find((datum: tResourceObject) => datum.id === newRow.id)
                )
            }
            break
        }
        // When we change the sort order, we want to remove any added rows that have been
        // saved to the database already. This allows them to sort properly
        case "SSRM_CLEAR_COMMITTED_NEW_ROWS": {
            newRows = newRows.filter(nr => !nr.id)
            break
        }
        case "SSRM_CLEAR_ALL_NEW_ROWS": {
            newRows = []
            break
        }
        default: {
            return state
        }
    }
    return { ...state, sourceData: newSourceData, gridIdCounter: newGridIdCounter, newRows }
}

// Referenceable Ids ----------------------------------------------------------

const initialReferenceableIdsState: tReferenceableIdsState = {
    companyFormSchemas: [],
    companyGroups: [],
    costCodes: [],
    costItems: [],
    changeOrders: [],
    companyCrewTypes: [],
    employees: [],
    employeeSchemas: [],
    materials: [],
    projectMaterials: [],
    equipment: [],
    projects: [],
    analyticsDashboards: [],
}

export const referenceableIds = (
    state: tReferenceableIdsState = initialReferenceableIdsState,
    action: tReferenceableIdsAction
): tReferenceableIdsState => {
    switch (action.type) {
        case "REFERENCEABLE_DATA_SET": {
            return action.payload
        }
        case "REFERENCEABLE_DATA_PARTIAL_SET": {
            return { ...state, ...action.payload }
        }
        default: {
            return state
        }
    }
}

// Network Status -------------------------------------------------------------

const initialNetworkStatusState: tNetworkStatusState = {
    promises: null,
    dataNavId: null,
    requestingData: false,
    loadingErrorMessage: "",
    actionErrorMessage: "",
    showActionError: false,
    saveStatus: "off",
}

export const networkStatus = (
    state: tNetworkStatusState = initialNetworkStatusState,
    action: tNetworkStatusAction
): tNetworkStatusState => {
    switch (action.type) {
        case "NETWORK_STATUS_UPDATE": {
            return { ...state, ...action.payload }
        }
        default: {
            return state
        }
    }
}
