import { _patch } from "./util"
import { urlForResource } from "../common/constants"

export const removeLockedPeriod = id => _patch(`/api/v4/company_options/${id}/`, { lock_end_date: null })

export const updateCompanyFormStore = (id, body, signedKey = "", email = "", fields = []) => {
    const url = signedKey ? "/api/v4/guest_form_update/" : `/api/v4/company_form_stores/${id}/`
    const payload = { ignore_schema_types: true, signedKey, email: email || null }
    payload.fields = fields
    return _patch(url, body, 200, false, false, payload)
}

export const updateGuestInfo = (body, signedKey, email) => {
    const url = "/api/v4/guest_info_update/"
    return _patch(url, body, 200, false, false, { signedKey, email })
}

export const updateRow = (resource, data) => _patch(`${urlForResource[resource]}${data.id}/`, data)

/**
 * Bulk update data.
 * @param {string} resource Resource name.
 * @param {*} data Data to be sent to the backend.
 */
export const updateRows = (resource, data) => _patch(`${urlForResource[resource]}bulk_update/`, data, 201)

export const updateSortOrder = (resource, data) =>
    _patch(`${urlForResource[resource]}bulk_update_sort_order/`, data, 200)

export const updateWorkComponent = workComponent =>
    _patch(`/api/v3/work_components/${workComponent.id}`, workComponent)

export const updateDailySummaryPreference = dailySummaryPreference =>
    _patch(`${urlForResource["dailySummaryPreferences"]}${dailySummaryPreference.id}/`, dailySummaryPreference)

export const updateCompanyLogo = (companyId, uploadInfo) =>
    _patch(`/api/v4/company/${companyId}/reference_update`, uploadInfo)

export const removeCompanyLogo = companyId => _patch("/api/v4/company/remove_logo/", { id: companyId })

export const updateSavedFilterSet = data => _patch(`/api/v4/saved_filter_sets/${data.id}/`, data, 200)

export const updateCompanySSOConfiguration = data => {
    return _patch(`/api/v4/company_sso_configuration/${data.id}`, data, 200)
}

export const createProjectImagePutUrl = data => _patch("/api/v4/projects/create_put_url/", data, 200)

export const updateWorkShift = (pk, data) => _patch(`/api/v4/work_shifts/${pk}/`, data, 200)

export const updateEmployeeStartOfWeek = (empId, startOfWeek) =>
    _patch(`/api/v4/employees/${empId}/start_of_week/`, { start_of_week: startOfWeek })

export const updateTimeline = (employeeId, payload) => _patch(`/api/v4/employees/${employeeId}/timeline`, payload)
