import {
    colorFunctionalBlack,
    colorFunctionalGray30,
    colorFunctionalWhite,
    colorMiscGray90,
    spacingS,
    spacingXs,
} from "@rhumbix/rmbx_design_system_web"
import styled from "styled-components"
import { eToolbarMode } from "../types"

import { getBackgroundColor, getTextColor } from "./utils"
import { getFlagEnabled } from "../../getFlagValue"

export const Button = styled.button`
    box-sizing: border-box;
    background-color: inherit;
    border-color: transparent;
    color: inherit;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    line-height: 1;
    min-width: 36px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;

    &:disabled {
        pointer-events: none;
    }

    &:focus,
    &:hover,
    &:hover:enabled {
        background-color: hsla(0, 0%, 100%, 0.5);
        opacity: 1;
    }
`

export const MenuContainer = styled.div`
    position: relative;
`

export const Menu = styled.ul`
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    list-style: none;
    margin: 0;
    min-width: 200px;
    padding: 0;
    position: absolute;
    top: 41px;
    left: -2px;
    z-index: 1000;
`

export const ChecklistWindow = styled.div`
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    margin: 0;
    min-width: 200px;
    padding: 0;
    position: absolute;
    top: 41px;
    left: -2px;
    z-index: 1000;
    height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const ChecklistButtonContainer = styled.div`
    display: flex;
    border-top: ${colorFunctionalGray30} solid 1px;
    padding: 8px;
    justify-content: right;
`

export const ChecklistList = styled.ul`
    list-style: none;
    padding-left: 0px;
`

export const MenuChoice = styled.li`
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: left;

    &:focus,
    &:hover {
        cursor: pointer;
        background-color: #f2f2f2;
    }
`

export const MenuButton = styled.button`
    box-sizing: border-box;
    background-color: transparent;
    border-color: transparent;
    color: ${colorMiscGray90};
    display: block;
    font-size: 14px;
    font-weight: normal;
    height: 36px;
    line-height: 1;
    padding: 8px 16px;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    white-space: nowrap;

    svg path {
        fill: currentColor !important;
    }

    &:disabled {
        pointer-events: none;
    }

    &:focus,
    &:hover,
    &:hover:enabled {
        color: #212121;
        opacity: 1;
    }
`

export const MenuIconContainer = styled.div`
    margin-left: -4;
    margin-right: ${spacingS};
    vertical-align: middle;
    width: 20px;
`

export const Icon = styled.svg`
    display: inline-block;
    height: 20px;
    margin-right: 4px;
    transform: scale(0.8);
    vertical-align: middle;
    width: 20px;
`

export const SaveIndicatorContainer = styled.div`
    font-weight: 500;
    padding: 8px;
    position: absolute;
    right: 4px;
    top: 4px;
    display: flex;
`

export const SaveIndicatorImage = styled.img`
    margin-left: 4px;
    height: 20px;
    width: 20px;
`

export const SmallerIcon = styled(Icon)`
    height: 16px;
    width: 16px;
    margin-right: 8px;
`

// N.B. The height must be 46px exactly, or the entire page layout breaks with
// a bunch of vertical scrollbars. The scrolling also makes modals misalign
// with the browser's pixel grid and makes modal text blurry. We should, uh,
// fix that at some point.
export const ToolbarContainer = styled.div`
    background-color: ${getBackgroundColor};
    border-bottom: 1px solid #808080;
    color: ${getTextColor};
    display: flex;
    flex-direction: row;
    height: 46px;
    min-width: 100%;
    padding: 4px;
    position: relative;
    align-items: center;

    & button svg path {
        fill: ${getFlagEnabled("WA-8154-ff-variants") ? "currentColor" : getTextColor};
    }
`

export const ToolbarText = styled.p`
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    padding: 12px 8px;
`

const getSeparatorTextColor = (toolbarMode: eToolbarMode) => {
    switch (toolbarMode) {
        case eToolbarMode.CELL:
            return colorFunctionalBlack
        case eToolbarMode.ROW:
        case eToolbarMode.GROUP_ROW:
            return colorFunctionalWhite
        case eToolbarMode.TABLE:
        default:
            return colorFunctionalGray30
    }
}

export const ToolbarButtonSeparator = styled.div<{ toolbarMode: eToolbarMode }>`
    height: 32px;
    width: 1px;
    margin: ${spacingXs};
    border-left: 1px solid
        ${({ toolbarMode }) =>
            toolbarMode === eToolbarMode.ROW ? colorFunctionalBlack : getSeparatorTextColor(toolbarMode)};
    opacity: ${() => {
        return 0.3
    }};
`
export const PercentOrHoursSelector = styled.select`
    border: none;
    position: absolute;
    left: 400px;
    top: 116.5px;
    background-color: transparent;
`
