import { ApprovalStatus, BaseTkEntry, tUserRole } from "../../common/types"
import { tContext, WeeklyViewSourceData } from "./types"
import { iTimekeepingStatus } from "../../cached-data/types"
import {
    colorAttentionBlue60,
    colorFunctionalGray50,
    IconCicoDeficit,
    IconCicoExcess,
    IconSubmittedTime,
} from "@rhumbix/rmbx_design_system_web"

const entriesAreAllSynced = (tkes: Pick<BaseTkEntry, "status">[]) =>
    tkes.length > 0 && tkes.every(tke => tke.status === ApprovalStatus.SYNCED)

const sourceDataIsAllSynced = (sourceData: WeeklyViewSourceData) => {
    const { absences, employeeEntries, timekeepingEntries, ewsStartStopTimes } = sourceData
    return entriesAreAllSynced([...absences, ...employeeEntries, ...timekeepingEntries, ...ewsStartStopTimes])
}

export const selectedCellHasAllSyncedData = (context: tContext): boolean => {
    // During a Fancy Search, selectedCellData is briefly an empty array (due to a table re-render?).
    // In any case, avoid failing.
    if (Array.isArray(context.selectedCellData)) {
        return false
    }
    return sourceDataIsAllSynced(context.selectedCellData)
}

const entriesUnableToTransition = (
    tkes: Pick<BaseTkEntry, "status">[],
    userRole: tUserRole,
    statuses?: iTimekeepingStatus[]
) => {
    if (tkes.length > 0) {
        if (statuses) {
            return tkes.some(tke => {
                if (!tke.status) return false
                const statusObj = statuses.find(s => {
                    const status = typeof tke.status === "object" ? tke.status.name : tke.status
                    return s.name === status
                })
                return !statusObj?.can_edit_cell || !statusObj.can_edit_cell.includes(userRole)
            })
        }
    }
    return false
}

const sourceDataUnableToTransition = (
    sourceData: WeeklyViewSourceData,
    userRole: tUserRole,
    statuses?: iTimekeepingStatus[]
) => {
    const { absences, employeeEntries, timekeepingEntries, ewsStartStopTimes } = sourceData
    return entriesUnableToTransition(
        [...absences, ...employeeEntries, ...timekeepingEntries, ...ewsStartStopTimes],
        userRole,
        statuses
    )
}

/**
 * Determines whether the current table context or selected cell can transition
 * @param context A context containing the data for the selected cell/table
 * @returns
 */
export const selectedCellUnableToTransition = (context: tContext): boolean => {
    // During a Fancy Search, selectedCellData is briefly an empty array (due to a table re-render?).
    // In any case, avoid failing.
    if (Array.isArray(context.selectedCellData)) {
        return false
    }
    const tkStatuses = context.referenceableData?.timekeepingStatuses
        ? Object.values(context.referenceableData.timekeepingStatuses)
        : undefined
    return sourceDataUnableToTransition(context.selectedCellData, context.currentUser.user_role, tkStatuses)
}

export const formatTimeDiff = (timeDiff: number) =>
    timeDiff > 0 ? `+${timeDiff.toFixed(2)}` : timeDiff === 0 ? "+0" : timeDiff.toFixed(2)

export const getCicoIcon = (timeDiff: number) =>
    timeDiff === 0 ? IconSubmittedTime : timeDiff > 0 ? IconCicoExcess : IconCicoDeficit

export const getCicoIconColor = (timeDiff: number) => (timeDiff >= 0 ? colorAttentionBlue60 : colorFunctionalGray50)
