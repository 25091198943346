import { format, isAfter, isBefore, isEqual, isValid, parseISO } from "date-fns"
/** Utils */
import Rmbx from "../util"
import { getAsDate, isDateTheNextDay } from "./ts-utils"
import { isModifyProdDataChanged } from "../components/custom-dashboards/ProductionTracking/utils"
/** Const */
import { CICO_TYPE_SHIFTS_AND_BREAKS_MAP, RESOURCE_TO_HUMAN_READABLE, STORED_DATE_ONLY_FORMAT } from "./constants"
import {
    MutableSourceData,
    TabbedTableWrapperTabParams,
    tResourceObject,
    tSourceData,
} from "../dashboard-data/types"
import cloneDeep from "clone-deep"
import { tResourceName } from "./types"
import { getSourceDataForGroupKeyInfo } from "./ag-grid-grouping-utils"
import { tContext, tGroupKeyInfo } from "../components/custom-dashboards/types"
import { tCompanyStartStopType, tEmployeeWorkShiftStartStopTime, tReferenceableState } from "../cached-data/types"
import { getFlagEnabled } from "../getFlagValue"
import { ColDef, GridApi, RowNode } from "ag-grid-community"
import { JsonPointer as jsonpointer } from "json-ptr"
const isNumber = Rmbx.util.isNumber
type IAggFuncParams = import("ag-grid-enterprise").IAggFuncParams

type tDateKeyParams = {
    value: string
}

export const dateKeyCreator = (params: tDateKeyParams): string => {
    const date = getAsDate(params.value)
    return isValid(date) ? format(date, STORED_DATE_ONLY_FORMAT) : "undefined"
}

export const dateIsSameOrAfter = (dateToCompareTo: string | Date, date: string | Date): boolean => {
    dateToCompareTo = getAsDate(dateToCompareTo)
    date = getAsDate(date)
    return isEqual(date, dateToCompareTo) || isAfter(date, dateToCompareTo)
}

export const dateIsSameOrBefore = (dateToCompareTo: string | Date, date: string | Date): boolean => {
    dateToCompareTo = getAsDate(dateToCompareTo)
    date = getAsDate(date)
    return isEqual(date, dateToCompareTo) || isBefore(date, dateToCompareTo)
}

export const lockedPeriodCellClassRule = (params: any): boolean => {
    // If there is no data on params - for example, because this is a grouping row -
    // return false
    if (!params.data) return false

    return dateIsSameOrAfter(params.data.date, params.context.currentUser.company_options.lock_end_date)
}

export const lockedPeriodPivotCellClassRule = (params: any): boolean => {
    if (!isPivotColumn(params.colDef)) {
        return false
    }
    return dateIsSameOrAfter(params.colDef.pivotKeys[0], params.context.currentUser.company_options.lock_end_date)
}

export const sumDurations = (values: Array<string>): string => {
    /*
    Given an array of duration strings in "HH:MM" format, split the strings, combine the totals, return string
    */
    let totalHours = 0
    let totalMinutes = 0
    // go through each val string, split it into hours and minutes, add them to the totals
    values.forEach(val => {
        const timeSplit = val.split(":")
        totalHours += isNumber(timeSplit[0]) ? Number(timeSplit[0]) : 0
        totalMinutes += isNumber(timeSplit[1]) ? Number(timeSplit[1]) : 0
    })
    // if the number of minutes >= to 60, convert minutes to whole hours and set totalMinutes to be the remainder
    if (totalMinutes >= 60) {
        totalHours += Math.floor(totalMinutes / 60)
        totalMinutes = totalMinutes % 60
    }
    return `${totalHours}:${String(totalMinutes).padStart(2, "0")}`
}

export const isPivotColumn = (colDef: any) => colDef.pivotKeys?.length > 0

/**
 * Determine whether the value changed.
 * Note:
 * - This logic is from custom-table.
 * - If both oldValue and newValue are nullish (undefined or null), it will be treated as the value didn't change.
 * @param {string|number|Array<any>|null} oldValue Old value.
 * @param {string|number|Array<any>|null} newValue New value.
 * @returns True if the value changed.
 */
export const isValueChanged = (
    oldValue: string | number | Array<any> | null,
    newValue: string | number | Array<any> | null
) => {
    if (Array.isArray(oldValue) && Array.isArray(newValue)) {
        if (oldValue.length !== newValue.length) return true

        // Compare the contents of the arrays element-for-element. If the same elements
        // are present but in a different sequence, that also indicates a change
        return !oldValue.every(function (value, index) {
            return value === newValue[index]
        })
    }

    // Using != so that undefined != null returns false (treating them as the same)
    return (oldValue != null || newValue != null) && oldValue !== newValue
}

/**
 * Get data that has been modified.
 * @param {Object[]} data All the sourceData.
 * @param {string} resource Resource name.
 * @returns {Object} Data that has been modified or errors if any.
 */
export const getModifiedData = (data: Readonly<Array<Record<string, any>>>, resource: string) => {
    if (!data.length) return {}
    const modifiedData: Array<Record<string, any>> = []
    for (const item of data) {
        if (!item.modified && !item.newRow) continue
        // Check if any errors.
        const { errors } = item
        if (errors && Object.keys(errors).length) return { errors: true }
        // Validate if data has changed for Modify Production view.
        if (resource === "modifyProduction" && !isModifyProdDataChanged(item)) continue

        modifiedData.push(item)
    }

    return { modifiedData }
}

/**
 * Between v22 and v26 of ag-grid, the Aggregator function parameters changed types. This attempts
 * to conform them and make typescript happy
 * @param params
 */
export const getAggParams = (params: IAggFuncParams | any[]): any[] => {
    if (Array.isArray(params)) return params
    return (params as IAggFuncParams).values
}

/**
 * Determine if two rows match.
 * @type {boolean}
 */
export const rowIdsMatch = (rowA: tResourceObject, rowB: tResourceObject, idAttr = "id"): boolean => {
    const idMatch = rowA[idAttr] != null && rowA[idAttr] === rowB[idAttr]
    const gridIdMatch = rowA.gridId != null && rowA.gridId === rowB.gridId
    if (getFlagEnabled("WA-8251-shift-extra-placeholder-duplication")) {
        return rowA?.schema?.id === rowB?.schema?.id && (idMatch || gridIdMatch)
    }
    return idMatch || gridIdMatch
}

/**
 * Callback when the source data in the table has been updated. Specifically
 * written for the weekly tk detail modal so that it would split out the modal's
 * table data from the data feeding the background table. We update the local state
 * with the entire set of the table data plus the list of updates
 * @param sourceData The data feeding the tabbed tables
 * @param sourceDataUpdates The list of updates that have happened to the data in the tables since the
 * modal was opened.
 * @param newRowId The initial grid ID for the new rows being created
 * @param resource Which resource type are we updating
 * @param rowData The new values for the rows in the table
 * @param isDelete Whether this was a delete operation
 * @param initialAdd If this row is just being added, we need to not mess with its isPlaceholder attribute
 * @returns {[newSourceData: *, newUpdates: *, newRow: boolean]} Returns the new set of source data that
 * should feed the tables, the updated set of changes that have happened to the tables since they were
 * opened, and whether this update resulted in new rows
 */
export const sourceDataUpdate = (
    sourceData: tSourceData,
    sourceDataUpdates: tSourceData,
    newRowId: number,
    resource: tResourceName,
    rowData: tResourceObject[],
    isDelete = false,
    initialAdd = false
): { newSourceData: MutableSourceData; newUpdates: tSourceData; numNewRows: number } => {
    const newSourceData = cloneDeep(sourceData) as MutableSourceData
    const newUpdates = cloneDeep(sourceDataUpdates) as MutableSourceData
    let numNewRows = 0

    rowData.forEach(row => {
        let data = row as tResourceObject

        // If this is a new row, we give it a few new attributes
        if (row.gridId === undefined) {
            data = { ...row, gridId: newRowId, newRow: true }
            if (!("isPlaceholder" in data)) data.isPlaceholder = true
            numNewRows += 1
        }
        newRowId++
        if (row.serverErrors?.length) {
            if (!data.errors) (data as any).errors = {}
            data.errors.serverErrors = row.serverErrors
        }
        row.serverErrors = []

        // Here we update the source data for the table
        if (!(resource in newSourceData)) {
            newSourceData[resource] = [data]
        } else {
            const resourceObjs = newSourceData[resource] as tResourceObject[]
            const index = resourceObjs.findIndex(r => rowIdsMatch(r, data))
            // If we can't find a row which matches this data, we add it as a new row
            if (index === -1) {
                resourceObjs.unshift(data)
            } else {
                // If we are deleting, remove the record from source data
                if (isDelete) {
                    resourceObjs.splice(index, 1)
                } else {
                    resourceObjs[index] = data
                    if (!initialAdd) {
                        resourceObjs[index].isPlaceholder = false
                    }
                }
            }
        }
        // We also need to track any updates so that it can be recorded in the database
        // when the user hits Save
        if (!(resource in newUpdates)) {
            newUpdates[resource] = [{ ...data, delete: isDelete }]
        } else if (resource in newUpdates) {
            const resourceObjs = newUpdates[resource] as tResourceObject[]
            const index = resourceObjs.findIndex(row => rowIdsMatch(row, data))
            if (index === -1) {
                resourceObjs.unshift({ ...data, delete: isDelete })
            } else if (data.newRow && isDelete) {
                resourceObjs.splice(index, 1)
            } else {
                resourceObjs[index] = { ...data, delete: isDelete }
            }
            newUpdates[resource] = resourceObjs
        }
    })

    return { newSourceData, newUpdates, numNewRows }
}

// Tabbed Table Wrapper helper functions
export const getTabGridParamsFromProps = (
    settings: Record<string, any>,
    lockedColumns: tGroupKeyInfo[] | null,
    sourceData: tSourceData,
    context: tContext
): TabbedTableWrapperTabParams[] => {
    const tableList: Record<string, any>[] = settings.tableList || []
    const filteredSourceData: tSourceData = lockedColumns
        ? getSourceDataForGroupKeyInfo(sourceData, lockedColumns, context)
        : sourceData
    return tableList.map(table => {
        const sourceDataKey = table.resources[0] as tResourceName
        const sourceDataValue = filteredSourceData[sourceDataKey] || []

        return {
            tabLabel: table.tableName,
            tabId: table.tableName,
            settings: table,
            sourceData: {
                [sourceDataKey]: [...sourceDataValue],
            },
        }
    })
}

export const getTabGridParamsFromSchemas = (
    settings: Record<string, any>,
    sourceData: tSourceData,
    lockedColumns: tGroupKeyInfo[] | null,
    context?: tContext,
    referenceableData?: tReferenceableState
): TabbedTableWrapperTabParams[] => {
    let schemaList: Record<string, any>[] = []
    const employeeSchemas = Object.values(referenceableData?.employeeSchemas ?? {})
    if (settings.includeTablesForAllSchemas) {
        schemaList = employeeSchemas
    } else if (settings.tableSchemaList) {
        schemaList = employeeSchemas.filter(ele => settings.tableSchemaList.includes(ele.name))
    }

    let schemaGridParams = schemaList.map(schema => {
        let employeeEntries = [] as tResourceObject[]
        if (sourceData.employeeEntries) {
            employeeEntries = sourceData.employeeEntries.filter(row => {
                // Make sure the row has a schema property - for example, dummy rows will not -
                // and then check if it's a match
                if (row.schema) {
                    const id = isNumber(row.schema) ? row.schema : row.schema.id
                    return id && id === schema.id
                }

                return false
            })
        }
        const schemaSourceData = { employeeEntries }
        const filteredSchemaSourceData = getSourceDataForGroupKeyInfo(schemaSourceData, lockedColumns, context)
        return {
            tabLabel: schema.name,
            tabId: `SE-${schema.id}`,
            schema: schema,
            sourceData: filteredSchemaSourceData,
        }
    })

    // Prune any tab that should not be displayed, specifically if
    // none of the projects in this query can use that schema. The
    // exception is if the schema already has some employeeEntries in place -
    // for example, because the user created some shift extras
    // and then removed that schema from their project
    if (context && Array.isArray(context.filters.projectId) && context.filters.projectId?.length > 0) {
        // If the view is filtered by project, only check those projects
        schemaGridParams = schemaGridParams.filter(
            (sgp: Record<string, any>) =>
                sgp.sourceData["employeeEntries"]?.length > 0 ||
                sgp.schema.projects.find(
                    (projectId: number) =>
                        Array.isArray(context.filters.projectId) &&
                        context.filters.projectId.find((id: number) => id === projectId)
                )
        )
    } else {
        // ... otherwise, check against all the projects in the list view's referenceableData
        const projectList = Object.values(referenceableData?.projects ?? {})

        schemaGridParams = schemaGridParams.filter(
            (sgp: Record<string, any>) =>
                sgp.sourceData["employeeEntries"]?.length > 0 ||
                sgp.schema.projects.find((projectId: number) => projectList.find(proj => proj.id === projectId))
        )
    }

    return schemaGridParams
}

/**
 * Get the parameters for each table.
 * @param settings settings for all the tabs
 * @param lockedColumns a list of the locked columns
 * @param sourceData the data feeding all the tabs of the table
 * @param context the table context
 * @param referenceableData All the referenceable data
 * @param sourceDataIsPrefiltered Whether the provided source data has already been filtered (an optimization)
 * @returns {{tabId: *, settings: *, tabLabel: *, sourceData: {}}[]}
 */
export const getAllTabsGridParams = (
    settings: Record<string, any>,
    lockedColumns: tGroupKeyInfo[] | null,
    sourceData: tSourceData,
    context: tContext,
    referenceableData: tReferenceableState,
    sourceDataIsPrefiltered?: boolean
) => {
    const tabGridParams: TabbedTableWrapperTabParams[] = getTabGridParamsFromProps(
        settings,
        sourceDataIsPrefiltered ? null : lockedColumns,
        sourceData,
        context
    )
    const schemaTabData = getTabGridParamsFromSchemas(
        settings,
        sourceData,
        lockedColumns,
        context,
        referenceableData
    )
    if (getFlagEnabled("WA-7987-equipment-tracking-updates") && settings?.schemasFirst) {
        schemaTabData.push(...tabGridParams)
        return schemaTabData
    }
    tabGridParams.push(...schemaTabData)
    return tabGridParams
}

/**
 * Simple helper function that takes a set of rows - usually all the rows for a given table -
 * and iterates through to find the highest gridId currently in use. New rows that are manually
 * added to the table can use that as the starting point when they assign new gridIds
 *
 * @param (tResourceObect) rows The table rows to search
 * @returns (number) The highest gridId in these rows, or 1 if no ID was found
 */
export const findHighestGridIdForRows = (rows: readonly tResourceObject[]): number => {
    let gridId = 1
    rows.forEach(row => {
        if (row.gridId && row.gridId > gridId)
            gridId = typeof row.gridId === "string" ? parseInt(row.gridId) : row.gridId
    })

    return gridId
}

type ShiftStartStopAGGroupRowNode = {
    allLeafChildren: {
        data: {
            created_on?: string
            company_start_stop_type?: tCompanyStartStopType
        } & tEmployeeWorkShiftStartStopTime
    }[]
}
/**
 * Given an AG Grid row node, finds a Shift row.
 * There may be more than one Shift row if there are multiple EWSs for the same employee represented
 * in the modal. In this case, the Shift row with the earliest created_on date is returned.
 * There may not be a Shift row at all (e.g. the user is currently adding start/stop times for an employee).
 */
export const findShiftRowData = (node: ShiftStartStopAGGroupRowNode) => {
    return node.allLeafChildren
        .sort((leafA, leafB) => {
            if (!leafA.data.created_on) return 1
            if (!leafB.data.created_on) return -1
            return leafA.data.created_on < leafB.data.created_on ? -1 : 1
        })
        .find(leaf => (leaf.data.company_start_stop_type?.is_break ?? leaf.data.is_break) === false)
}

export type SimplifiedGroupNode = {
    allLeafChildren?: Array<SimplifiedGroupNode>
    childrenAfterGroup?: Array<SimplifiedGroupNode>
    data?: Record<string, any>
}
/**
 * Workaround for agGrid 26 in order to get the data to display for the group node.
 *
 * Due to a change in agGrid 26, after making an edit to a group cell,
 * the value for allLeafChildren becomes an empty list, which prevents us from getting the data we needed.
 * This attempts to workaround it by traversing
 * the children via childrenAfterGroup in order to get a non-empty value for allLeafChildren
 * If grouping by timecard for example:
 * <Timecard Owner>
 *      <Workshift ID>
 *          <Employee>
 *              <Cost Code>
 * The function below will get the row data necessary to fill in the proper values
 * for each of the group nodes (i.e <Timecard Owner>, <Workshift ID>, etc)
 *  https://www.ag-grid.com/react-data-grid/row-object/#reference-groupNodeAttributes-childrenAfterGroup
 * @params groupNode
 * @params depth
 * @params maxDepth
 */
export const findGroupDataFromChildrenAfterGroup = (
    groupNode: SimplifiedGroupNode,
    depth = 0,
    maxDepth = 5
): Record<string, any> | undefined => {
    // we don't want to infinitely recur in the case allLeafChildren is not found,
    // while childrenAfterGroup continues to have a value, so set an arbitrary
    // max depth to stop recurring
    if (depth === maxDepth) {
        return undefined
    } else if (groupNode.allLeafChildren?.length && groupNode.allLeafChildren[0]?.data) {
        return groupNode.allLeafChildren[0]?.data
    } else if (groupNode.childrenAfterGroup?.length) {
        return findGroupDataFromChildrenAfterGroup(groupNode.childrenAfterGroup[0], depth + 1, maxDepth)
    }
    return undefined
}

export const isPlaceholder = (row: Record<string, any>): boolean => row?.isPlaceholder

export const doesShiftEndingNextDayExist = (api: GridApi, workShiftDate: Date, employeeId: number) => {
    let nextDayShiftEnd = false
    api.forEachNode(rowNode => {
        const stopTime = parseISO(rowNode.data?.stop_time)
        const rowEmployeeId = isNumber(rowNode.data?.employee) ? rowNode.data?.employee : rowNode.data?.employee?.id
        const isBreak = rowNode.data?.company_start_stop_type?.is_break || rowNode.data?.is_break
        if (
            isBreak === false &&
            stopTime &&
            employeeId &&
            employeeId === rowEmployeeId &&
            isDateTheNextDay(workShiftDate, stopTime)
        )
            nextDayShiftEnd = true
    })
    return nextDayShiftEnd
}

/**
 * A common function for the slide toggle column editor and renderer
 * @param row {RowNode} The Ag Grid row node being affected
 * @param context {tContext} The table context
 * @param colDef {ColDef} The Ag Grid column definition
 * @param gridApi {GridApi} The Ag Grid Grid API
 * @param currentValue {string[] | boolean} The cell's current value
 * @param setCurrentValue {Function} Callback function to set the new value
 * @param isListValueType {boolean} Whether this slider represents a list value. This is the case for the
 * TK Statuses dashboard with the user role toggles.
 * @param onValue {string} The value to set if the slider is on
 */
export const slideToggleHandleChange = (
    row: RowNode,
    context: tContext,
    colDef: ColDef,
    gridApi: GridApi,
    currentValue: string[] | boolean,
    setCurrentValue: (newValue: string[] | boolean) => void,
    isListValueType?: boolean,
    onValue?: string
) => {
    if (row && context && colDef && gridApi) {
        if (colDef?.field === "/options/show_new_field_names") {
            const title = currentValue ? "Revert to Classic Form Names" : "Update to Newest Field Names"
            const revertToClassic = `Turning this option off will change the data returned from
                        the shift_extra_entries, project_entries (api v2), and workflow_entries (api v3) endpoints,
                        and could break existing integrations. `
            const updateToNewest = `Turning this option on could break existing integrations that
                        rely on shift_extra_entries, project_entries (api v2), and workflow_entries (api v3).`
            const description = currentValue ? revertToClassic : updateToNewest
            context.createModalAction({
                title: title,
                description: description,
                action: () => {
                    row.setDataValue("/options/show_new_field_names", !currentValue)
                    gridApi.clearFocusedCell()
                },
                buttonClass: currentValue ? "continueButton" : "",
                close: () => {
                    context.createModalAction(null)
                    gridApi.clearFocusedCell()
                },
            })
        } else if (isListValueType && onValue) {
            const value = jsonpointer.get(row.data, colDef.field as string) as string[]
            let newValue
            if (!value) newValue = [onValue]
            else {
                const index = value.indexOf(onValue)
                if (index > -1) {
                    newValue = value.filter(role => role !== onValue)
                } else {
                    newValue = [...value, onValue]
                }
            }
            setCurrentValue(newValue)
            gridApi.clearFocusedCell()
        } else if (colDef.field) {
            setCurrentValue(!currentValue)
            gridApi.clearFocusedCell()
        }
    }
}

export const rowHasErrors = (row: tResourceObject): boolean =>
    !!(
        row.errors &&
        Object.values(row.errors).reduce((acc: boolean, errors) => acc || !!(errors as string[]).length, false)
    )

export const rowHasAlerts = (row: tResourceObject): boolean =>
    !!(
        row.alerts &&
        Object.values(row.alerts).reduce((acc: boolean, alerts) => acc || !!(alerts as string[]).length, false)
    )

export const getDescriptionOfSourceData = (sourceData: tSourceData) => {
    let description = ""
    let numRecords = 0
    Object.entries(sourceData).forEach(([resource, records]) => {
        numRecords += records.length
        description += records.length
            ? `&emsp;${records.length} ${
                  RESOURCE_TO_HUMAN_READABLE[
                      resource as "absences" | "timekeepingEntries" | "employeeEntries" | "ewsStartStopTimes"
                  ][records.length == 1 ? "single" : "plural"]
              }<br>`
            : ""
    })
    return { description, numRecords }
}

/**
 * Get the object id from an object attribute. Sometimes it's an ID already, sometimes it's an object
 * @param obj
 */
export const getObjectId = (obj: Record<string, any> | number): number =>
    obj && typeof obj === "object" ? obj.id : obj

/**
 * Check whether some cell data is compatible with another column. Used when trying to find a match from
 * copying/pasting a range of data. Normally it's enough to just compare source types, but in the case of
 * an enum, we want to make sure the data is one of the enum choices.
 * @param columnType {string} The type of the destination column
 * @param destCell {Record<string, any>} The potential source data
 * @param colDef {ColDef} Column definition for the destination column
 * @param value {any} The value we're trying to paste
 */
export const cellValuesAreCompatible = (
    columnType: string,
    destCell: Record<string, any>,
    colDef: ColDef,
    value: any
) =>
    columnType === destCell.sourceType &&
    (!colDef.cellEditorParams?.values || colDef.cellEditorParams?.values.includes(value))

/**
 * Take the CI/CO data from the back-end and make it into the expected format for Shifts + Breaks.
 * We filter out anything that isn't a shift, meal or break start.
 * @param cicoRows {tResourceObject[]} The cico data from the API
 */
export const convertCicoToShiftAndBreak = (cicoRows: readonly tResourceObject[]): tResourceObject[] => {
    const clockedInDuration = cicoRows.reduce((agg, row) => {
        agg += row.clocked_in_duration || 0
        return agg
    }, 0)
    cicoRows.forEach(cicoRow => {
        cicoRow.start_time = cicoRow.entered_time
        // This is probably a bit broken with CHANGE_TASK events
        const duration = cicoRow.entry_type === "CLOCK_IN" ? clockedInDuration : cicoRow.duration
        cicoRow.stop_time = new Date(new Date(cicoRow.start_time).getTime() + duration * 60000).toISOString()
        switch (cicoRow.entry_type) {
            case "CLOCK_IN":
            case "CHANGE_TASK":
                cicoRow.start_stop_type = "Shift"
                cicoRow.is_break = false
                break
            case "START_MEAL":
                cicoRow.start_stop_type = "Meal"
                cicoRow.is_break = true
                break
            case "START_BREAK":
                cicoRow.start_stop_type = "Break"
                cicoRow.is_break = true
                break
            default:
                cicoRow.duration_minutes = 0
        }
    })

    return cicoRows.filter(r =>
        ["CLOCK_IN", "START_MEAL", "START_BREAK", "CHANGE_TASK"].includes(r.entry_type)
    ) as tResourceObject[]
}

/**
 * Takes the shift and break data along with the cico data and groups the rows together. We loop through
 * the shifts and breaks and try to find a cico entry that matches the employee and type. If there are more
 * than one (like multiple meals or breaks), we match with the closest start time. We also keep track of which
 * ones we've used so we don't double-up. If there isn't a match, we fake a row indicating that that is the case
 * @param cicoData {tResourceObject[]} Timeline entry version data from the API
 * @param shiftsAndBreaks {tResourceObject[]} Employee start/stop times from the API
 * @param showCico {boolean} Whether we are showing the cico rows in the UI. We still need this function
 * to compute the cico delta column in any case.
 */
export const sortShiftsAndBreaksWithCicoData = (
    cicoData: tResourceObject[],
    shiftsAndBreaks: readonly tResourceObject[],
    showCico: boolean
): tResourceObject[] => {
    const output: tResourceObject[] = []
    const usedCicoIds = new Set()
    shiftsAndBreaks.forEach((row, idx) => {
        const rowType = CICO_TYPE_SHIFTS_AND_BREAKS_MAP[row.start_stop_type as "Shift" | "Break" | "Meal"]
        const shiftBreakEmployeeId = getObjectId(row.employee)
        const matchingCicoRows = cicoData.filter(cicoRow => {
            const employeeId = getObjectId(cicoRow.employee)
            return (
                employeeId === shiftBreakEmployeeId &&
                rowType === cicoRow.entry_type &&
                !usedCicoIds.has(cicoRow.id)
            )
        })

        if (matchingCicoRows.length) {
            let minTimeDiff: number | null
            let minIdx = 0
            matchingCicoRows.forEach((cicoRow, idx) => {
                const startTimeDiff = new Date(cicoRow.start_time).getTime() - new Date(row.start_time).getTime()
                if (!minTimeDiff || startTimeDiff < minTimeDiff) {
                    minTimeDiff = startTimeDiff
                    minIdx = idx
                }
            })
            const cicoDuration =
                (new Date(matchingCicoRows[minIdx].stop_time).getTime() -
                    new Date(matchingCicoRows[minIdx].start_time).getTime()) /
                60000
            const rowDuration = (new Date(row.stop_time).getTime() - new Date(row.start_time).getTime()) / 60000
            output.push({
                ...row,
                cico_diff: ((rowDuration - cicoDuration) / 60).toFixed(2),
            })
            usedCicoIds.add(matchingCicoRows[minIdx].id)
            if (showCico) {
                output.push({ ...matchingCicoRows[minIdx], gridId: (row.gridId as number) * -1 })
            }
        } else {
            output.push({ ...row, cico_diff: row.duration_minutes / 60 })
            if (showCico)
                output.push({
                    gridId: idx * -1,
                    id: idx * -1,
                    employee: row.employee,
                    start_stop_type: "No Data Available",
                    duration_minutes: "--",
                    workshiftId: { id: -1 },
                    status: "__NONE__",
                })
        }
    })

    if (showCico)
        cicoData
            .filter(row => !usedCicoIds.has(row.id))
            .forEach(row => {
                output.push({ ...row, gridId: row.id! * -1 })
            })

    return output
}
