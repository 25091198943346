import Rmbx from "../util"
import makeRequest from "../networkClient"
import { _call, _formatDate, _get } from "./util"
import { urlForResource } from "../common/constants"
import { getFlagEnabled } from "../getFlagValue"

export const exportCmic = (project_id, startDate, endDate) => {
    const parameters = {
        shift_start_time_0: _formatDate(startDate),
        shift_start_time_1: _formatDate(endDate),
        project_id: project_id,
    }

    // Need to set custom accept header, since we are downloading CSV files
    // instead of JSON.
    return makeRequest(
        {
            method: "GET",
            url: `/api/v4/timekeeping_entries/cmic_export/?${Rmbx.util.serializeQueryParams(parameters)}`,
            headers: new Map([["accept", "*/*"]]),
        },
        null,
        true,
        false
    )
}

export const exportCSV = (
    projectId,
    startDate,
    endDate,
    format,
    statuses,
    groupIds = [],
    show_new_field_names = false,
    cohortIds = []
) => {
    const parameters = {
        start_date: _formatDate(startDate),
        end_date: _formatDate(endDate),
        project_id: projectId ? projectId : undefined,
        export_format_id: format ? format : undefined,
        statuses: statuses ? statuses : undefined,
        group_id: groupIds,
        show_new_field_names: show_new_field_names,
        cohort_id: cohortIds,
    }

    // Need to set custom accept header, since we are downloading CSV files
    // instead of JSON.
    return makeRequest(
        {
            method: "GET",
            url: `/api/v3/reports/export_csv/?${Rmbx.util.serializeQueryParams(parameters)}`,
            headers: new Map([["accept", "*/*"]]),
        },
        null,
        true,
        false
    )
}

export const getAbsences = (projectId, startDate, endDate, page_size = 4096) =>
    _get("/api/v3/absences/", {
        project_id: projectId,
        start_date: _formatDate(startDate),
        end_date: _formatDate(endDate),
        page_size: page_size,
    })

export const getApiIntegrationName = () => _get("/api/v3/company_integration/")

export const getAuditHistoryData = (resource, filters) =>
    _get(
        `${urlForResource[resource]}history/`,
        {
            foreman_id: filters["foreman_id"] ? filters["foreman_id"] : undefined,
            employee_id: filters["employee_id"] ? filters["employee_id"] : undefined,
            shift_start_time_0: filters["startDate"] ? _formatDate(filters["startDate"]) : undefined,
            shift_start_time_1: filters["endDate"] ? _formatDate(filters["endDate"]) : undefined,
            cost_code_id: filters["cost_code_id"] ? filters["cost_code_id"] : undefined,
            status: filters["status"] ? filters["status"] : undefined,
            employee_trade: filters["employee_trade"] ? filters["employee_trade"] : undefined,
            equipment_id: filters["equipment_id"] ? filters["equipment_id"] : undefined,
            page_size: 10000,
        },
        200,
        false,
        true
    )

export const getAuditHistoryByIdData = (resource, id, queryParams) => {
    if (resource === "companyFormStores") {
        // we want to return deleted forms when looking at history
        // so users can see the delete action history entry
        queryParams["include_deleted"] = true
    }
    return _get(`${urlForResource[resource]}${id}/history/`, queryParams, 200, false, true)
}

export const getAutodeskAuthUrl = () => _get("/integrations/autodesk/auth-url/")

export const getAutodeskToken = () => _get(urlForResource.autodeskToken)

export const getAutodeskProjects = () => _get("/integrations/autodesk/projects/")

export const getS3DownloadLink = (taskId, formId, signedKey = "") => {
    const queryParams = {
        task_id: taskId,
        form_id: formId,
    }

    return signedKey
        ? _get(`/api/v4/download_pdf_as_guest/${signedKey}/`, queryParams)
        : _get("/api/v4/download_pdf_as_user/", queryParams)
}

export const getBudgetItems = (queryParams = {}) =>
    _get("/api/v3/budget_items/", {
        ...queryParams,
        source: "FIELD",
        version: "CURRENT",
    })

export const getCompanyFormSchemas = (filters = {}, sharedKey = null, formId = null, variantName = null) => {
    if (sharedKey) {
        const queryParams = getFlagEnabled("WA-8154-ff-variants") && variantName ? { variant: variantName } : {}
        return _get(`/api/v4/shared_schema/${formId}/${sharedKey}`, queryParams)
    }
    return _get("/api/v4/company_form_schemas/", filters)
}

export const getCompanyFormSchemaProjects = schemaId => {
    return _get(`/api/v4/company_form_schemas/${schemaId}/projects`)
}

export const getCompanyFormSchemaWorkflow = params => {
    return _get("/api/v4/company_form_schemas/workflow", params)
}

export const getCostCodeLogForOneDay = (projectId, startDate, endDate) =>
    _get("/api/v3/cost_codes/", {
        project_id: projectId ? projectId : undefined,
        start_date: _formatDate(startDate),
        end_date: _formatDate(endDate),
    })

export const getCurrentUser = () => _get("/api/v3/users/me/")

export const getCurrentUserStartOfWeek = empId => _get(`/api/v4/employees/${empId}/start_of_week/`)

export const getCustomFormData = (id, sharedKey, historyId) => {
    // we allow users to view soft-deleted forms - so pass along include_deleted param
    const queryParams = { include_deleted: true, ignore_schema_types: true, history_id: historyId ?? null }
    if (sharedKey) {
        return _get(`/api/v4/shared/${id}/${sharedKey}`, queryParams)
    }
    return _get(`/api/v4/company_form_stores/${id}/`, queryParams)
}

export const getCustomFormDataHistory = (id, history_id) => {
    // the include_deleted param allows us to view the deleted form in the history view
    return _get(`/api/v4/company_form_stores/${id}/history/${history_id}`, {
        include_deleted: true,
        ignore_schema_types: true,
    })
}

export const getCustomUserLinks = () => _get("/api/v4/custom_user_links/")

export const getDataFromSearchv4 = (q, resource, extraQueryParams = {}) =>
    _get(`${urlForResource[resource]}search/`, { ...extraQueryParams, q }, 200)

export const getEmployees = (queryParams = {}) => _get("/api/v3/employees/", queryParams)

export const getGuestFormShares = (queryParams = {}) => _get("/api/v4/guest_form_shares/", queryParams)

export const getEmployeeWorkShifts = (projectId, startDate, endDate, page_size = 4096) =>
    _get("/api/v3/employee_work_shifts/", {
        project_id: projectId,
        start_date: _formatDate(startDate),
        end_date: _formatDate(endDate),
        page_size: page_size,
    })

export const getEmployeeWorkShiftToSign = (id, combinedKey, queryParams = {}) =>
    _get(`/api/v4/sign/${id}:${combinedKey}/`, queryParams)

export const getEquipment = projectId =>
    _get("/api/v3/equipment/", {
        project_id: projectId,
    })

export const getExportFormats = () => _get("/api/v3/export_formats/")

export const getProjectEmployees = (projectId, queryParams = {}) => {
    if (!projectId) {
        return Promise.resolve()
    }

    return _get(`/api/v3/projects/${projectId}/users/`, queryParams)
}

export const getProjectMaterials = projectId => {
    if (!projectId) {
        return Promise.resolve()
    }

    return _get("/api/v4/project_materials/", { projectId })
}

export const getMaterials = () => {
    return _get("/api/v4/materials/")
}

export const getProjectToDateQuantityTotals = projectId =>
    _get(`/api/v3/projects/${projectId}/cost_codes/total_qty_to_date/`)

export const getQuantities = (projectId, startDate, endDate) =>
    _get("/api/v3/quantities/", {
        project_id: projectId,
        project_status: "ACTIVE",
        start_date: _formatDate(startDate),
        end_date: _formatDate(endDate),
    })

export const getRowData = (resource, queryParams, page_size = 50000) => {
    const queryParamsWithPagination = {
        ...queryParams,
        page_size: page_size,
    }
    return _get(urlForResource[resource], queryParamsWithPagination)
}

export const getSSRowData = (resource, agGridParams, filterParams, additionalParams, page_size, searchTerm) => {
    const page_number = agGridParams.startRow / page_size + 1

    const queryParams = {
        ...filterParams,
        ...additionalParams,
        page_size,
        page: page_number,
    }
    if (searchTerm) queryParams["q"] = searchTerm
    if (agGridParams.sortModel.length) {
        queryParams.order_by = agGridParams.sortModel.reduce((agg, item) => {
            const term = item.sort === "asc" ? item.colId.substring(1) : `-${item.colId.substring(1)}`
            agg.push(term)
            return agg
        }, [])
    }
    const url = searchTerm ? `${urlForResource[resource]}search/` : urlForResource[resource]
    return _get(url, queryParams)
}

export const getTmInviteCampaignCookie = () => _get("/api/v3/users/get_tm_invite_campaign_cookie/")

export const getDailySummaryPreferences = () => {
    return _get(urlForResource["dailySummaryPreferences"])
}

export const getListOfTransformsOptionsData = id => {
    return _get("/api/v4/transforms/", {
        from_schema_id: id,
        to_schema_type: "NULL",
    })
}

export const getTransforms = data => {
    return _get("/api/v4/transforms/", data)
}

export const getProjects = filters => {
    return _get(urlForResource["projects"], filters)
}

export const createLookerURL = data => _get("/integrations/looker/signed-url/", data)

export const getCustomFormMaxHistory = id => {
    return _get(`/api/v4/company_form_stores/${id}/history/latest/`, { ignore_schema_types: true })
}

export const getCompanyFormStores = data => {
    return _get("/api/v4/company_form_stores/", data)
}

export const getGuestUsers = data => {
    return _get("/api/v4/guest_users/", data)
}

export const getCompanyInfo = companyId => {
    return _get(`/api/v4/company/${companyId}`)
}

export const getExternal = url => {
    return _call("GET", url, null)
}

export const getCompanyGroupSchemaSettings = schemaId => {
    return _get("/api/v4/company_group_schemas/", {
        schema_id: schemaId,
    })
}

export const getSharePreviousCollaborators = data => {
    return _get("/api/v4/guest_form_shares/", data)
}

export const getListViewSchemaPermissions = (listView, projectIds) => {
    const params = { schema_name: listView?.schema_name }
    if (projectIds.length) params["project_ids"] = projectIds
    return _get("/api/v4/schema_permissions/", params)
}

export const getFormSharePermissions = params => {
    return _get("/api/v4/guest_form_shares/get_guest_permissions_for_forms/", params)
}

export const getFormShareGuestVariants = params => {
    return _get("/api/v4/guest_variants/", params)
}

export const getV4Resources = (resource, queryParams = {}) => {
    return _get(urlForResource[resource], queryParams)
}

export const getEmployeeTimeline = (employeeId, queryParams) => {
    return _get(`/api/v4/employees/${employeeId}/timeline`, queryParams)
}

export const getAbsenceCountByType = (queryParams = {}) =>
    _get("/api/v4/company_absence_types/get_absence_count_by_type/", queryParams)

export const getSavedFilterSets = (queryParams = {}) => _get("/api/v4/saved_filter_sets/", queryParams)

export const getMinimalProjects = () => _get("/api/v4/projects/fetch_all_company_projects")

export const getMinimalEmployees = () => _get("/api/v4/employees/fetch_all_company_employees")

export const getMinimalMaterials = () => _get("/api/v4/materials/fetch_all_company_materials")

export const getMinimalEquipment = () => _get("/api/v4/equipment/fetch_all_company_equipment")

export const getCompanySSOConfiguration = () => _get("/api/v4/company_sso_configuration")

export const exchangeCodeForAccessToken = (queryParams = {}) => _get("/dj-rest-auth/oidc-login/", queryParams)

export const getWorkShiftProjects = workShiftId =>
    _get(`/api/v4/work_shifts/${workShiftId}/projects/`, { get_format: "MINIMAL" })

export const getWorkShiftById = (workShiftId, allow_empty = false) =>
    _get(`/api/v4/work_shifts/${workShiftId}/`, { allow_empty })

export const getFormStoreVariants = params => _get("/api/v4/form_variants/available_variant_names/", params)

export const getVariantTemplates = params => _get("/api/v4/form_variants/templates/", params)
