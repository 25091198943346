import React, { Component } from "react"
import styled from "styled-components"
import {
    colorAttentionBlue60,
    colorAttentionRed60,
    colorAttentionYellow50,
    IconCicoDeficit,
    IconCicoExcess,
    IconSubmittedTime,
    IconCraftWorker,
} from "@rhumbix/rmbx_design_system_web"
import { connect } from "react-redux"
import { getCookie } from "../../../common/ts-utils"

class CicoEmployeeCellRenderer extends Component {
    refresh() {
        return true
    }

    formatTime = timeInMinutes => {
        const value = `${Math.floor(Math.abs(timeInMinutes) / 60)}:${Math.abs(timeInMinutes % 60).toLocaleString(
            "en-US",
            {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }
        )}`
        if (timeInMinutes < 0) return `-${value}`
        return value
    }

    render() {
        const { value } = this.props

        // value should never be undefined, but occasionally it is (probably while the page
        // is still loading), so catch that here
        if (!value) {
            return <div className="group-cell"></div>
        }
        let showException = false
        if (this.props.cicoExceptions) {
            const exceptionUnits = getCookie("exception_units")
            const exceptionThreshold = getCookie("exception_threshold")
            if (exceptionUnits === "hours") showException = value.cicoDiff / 60 > exceptionThreshold
            else if (exceptionUnits === "percent")
                showException = Math.abs(value.cicoDiff / value.clocked_in_duration) > exceptionThreshold / 100
        }

        const title = this.props.valueFormatter({ value })
        const subtitle = this.props.primarySubtitleFormatter({ value })
        const CicoIcon =
            value.cicoDiff === 0 ? IconSubmittedTime : value.cicoDiff > 0 ? IconCicoExcess : IconCicoDeficit
        const cicoIconColor = value.cicoDiff >= 0 ? colorAttentionBlue60 : colorAttentionRed60
        const cicoTextColor = showException
            ? colorAttentionYellow50
            : value.cicoDiff >= 0
            ? colorAttentionBlue60
            : colorAttentionRed60

        const formattedTime = this.formatTime(value.cicoDiff)

        return (
            <CellContainer className="group-cell">
                <div>
                    <div className="group-cell-title">{title}</div>
                    <Subtitle className="group-cell-text">
                        <span>{subtitle}</span>
                        <CicoGapValue style={{ color: cicoTextColor }}>
                            {showException ? (
                                // I don't have the right exception icon for this yet
                                <IconCraftWorker
                                    width={12}
                                    color={colorAttentionRed60}
                                    style={{ marginRight: 3 }}
                                />
                            ) : (
                                <CicoIcon width={12} color={cicoIconColor} style={{ marginRight: 3 }} />
                            )}
                            {value.cicoDiff > 0 ? `+${formattedTime}` : formattedTime}
                        </CicoGapValue>
                    </Subtitle>
                </div>
            </CellContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        cicoExceptions: state.searchBar.cicoExceptions,
        // We need this so as to force a redraw when the value changes
        cicoExceptionValue: state.searchBar.cicoExceptionValue,
    }
}

export default connect(mapStateToProps)(CicoEmployeeCellRenderer)

const CellContainer = styled.div`
    width: 100%;
`

const Subtitle = styled.div`
    display: flex;
    justify-content: space-between;
`

const CicoGapValue = styled.span`
    margin-left: 10px;
`
