import {
    attachmentsColDefCreator,
    checkboxColDefCreator,
    datetimeColDefCreator,
    foremanColDefCreator,
    projectColDefCreator,
    stringColDefCreator,
    viewCicoHistoryButtonColDefCreator,
} from "./standard-col-def-creators"
import { addDays, format, isValid } from "date-fns"
import { getAsDate, getTimezoneAcronym } from "../../../common/ts-utils"
import { CICO_LOOKUP, DATETIME_FORMAT } from "../../../common/constants"
import { tGroupKeyInfo } from "../types"
import { iTimelineEntryVersion } from "../../../cached-data/types"
import { CellClassParams, EditableCallbackParams, GridApi } from "ag-grid-community"

export const getCicoTableSettings = (lockedColumns: tGroupKeyInfo[]) => {
    const dateParts = lockedColumns.find(col => col.colDef.field === "/date")!.value.split("-")
    const currentDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
    return {
        tableName: "Cico",
        navId: "cico",
        resources: ["timelineEntries"],
        filters: [],
        additionalQueryParams: {
            for_dashboard: true,
        },
        relatedNames: {
            timelineEntryVersions: {
                employees: ["/employee"],
            },
        },
        colDefs: [
            checkboxColDefCreator({}),
            stringColDefCreator({
                headerName: "Type",
                field: "/entry_type",
                enum: Object.values(CICO_LOOKUP),
                getQuickFilterText: params => CICO_LOOKUP[params.value as keyof typeof CICO_LOOKUP],
                valueFormatter: params => CICO_LOOKUP[params.value as keyof typeof CICO_LOOKUP],
                valueSetter: params => {
                    params.data.entry_type = Object.keys(CICO_LOOKUP).find(
                        key => CICO_LOOKUP[key as keyof typeof CICO_LOOKUP] === params.newValue
                    )
                    return params.data.entry_type
                },
            }),
            datetimeColDefCreator({
                headerName: "Timestamp",
                field: "/entered_time",
                editable: true,
                extraParams: {
                    minuteInterval: 1,
                },
                cellEditorParams: {
                    getMinDate: () => currentDate,
                    // Set the maximum date to the next day for clock-out entries or to the clock out time
                    // if one exists. Otherwise limit it to today.
                    getMaxDate: (params: { data: iTimelineEntryVersion; api: GridApi }) => {
                        if (params.data.entry_type === "CLOCK_OUT") return addDays(currentDate, 1)

                        let shiftEndDate
                        params.api.forEachNode(node => {
                            if (node.data.entry_type === "CLOCK_OUT") shiftEndDate = node.data.entered_time
                        })
                        if (shiftEndDate) return new Date(shiftEndDate)
                        return currentDate
                    },
                    getOpenToDate: () => currentDate,
                },
                valueFormatter: params => {
                    if (!params.value) {
                        return ""
                    }
                    // add the timezone acronym to the timestamp unless it is a date-only cell
                    const tz = getTimezoneAcronym(
                        params.data.audit?.client_created_on_timzeone ||
                            Intl.DateTimeFormat().resolvedOptions().timeZone
                    )
                    const date = getAsDate(params.value)
                    return isValid(date) ? `${format(date, DATETIME_FORMAT)} ${tz}` : ""
                },
            }),
            {
                ...foremanColDefCreator({
                    field: "/references/foreman",
                }),
                editable: (params: EditableCallbackParams) => !params.data.no_foreman,
                cellStyle: (params: CellClassParams) => (params.data.no_foreman ? { opacity: 0.5 } : {}),
            },
            {
                ...projectColDefCreator({
                    field: "/references/project",
                }),
                editable: (params: EditableCallbackParams) => !params.data.no_project,
                cellStyle: (params: CellClassParams) => {
                    return params.data.no_project ? { opacity: 0.5 } : {}
                },
            },
            attachmentsColDefCreator({
                headerName: "Location",
                field: "/audit/location_image",
                editable: false,
                width: 100,
            }),
            viewCicoHistoryButtonColDefCreator({
                cellRendererParams: {
                    lockedColumns,
                },
            }),
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: true,
            },
            rowSelection: "multiple",
            // allow our click events to happen
            suppressRowClickSelection: true,
        },
        otherSettings: {
            pageTitle: "Clock In/Clock Out",
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            buttons: {
                row: [
                    {
                        label: "Delete",
                        icon: "delete",
                        args: { local_state_update: true },
                        action: "deleteSelectedRows",
                    },
                ],
                table: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRow",
                    },
                ],
            },
        },
    }
}
