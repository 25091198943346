import React from "react"
import {
    colorAttentionBlue50,
    colorFunctionalGray50,
    colorFunctionalGray70,
    IconSubmittedTime,
} from "@rhumbix/rmbx_design_system_web"
import styled from "styled-components"

/**
 * This is a custom react-select option component for the Employee picker which will
 * show CI/CO information. It relies on an annotation in the Employee view which populates
 * the work_duration field.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Option = props => {
    const formatOption = (formatter, className, defaultValue = "") => {
        const value = formatter
            ? formatter({
                  value: props.option ?? null,
                  context: props.context,
              })
            : defaultValue

        // Make sure "value" is not null|undefined|"", else will cause CSS issue.
        if (value != null && value !== "") return <div className={className}>{value}</div>
    }
    const formatTime = timeInMinutes =>
        `${Math.floor(timeInMinutes / 60)}:${Math.abs(timeInMinutes % 60).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })}`

    const titleFormatter = props.titleFormatter ? props.titleFormatter : props.valueFormatter
    const CicoIcon = IconSubmittedTime
    return (
        <StyledOption className="option-content">
            <div style={{ width: "100%" }}>
                <div className="group-cell-title">{formatOption(titleFormatter, "option-title")}</div>
                <div className="group-cell-text" style={{ display: "flex", justifyContent: "space-between" }}>
                    <span> {formatOption(props.primarySubtitleFormatter, "option-primarySubtitle")}</span>
                    <CicoGapValue>
                        <CicoIcon
                            width={12}
                            height={12}
                            color={colorAttentionBlue50}
                            style={{ marginRight: 3, marginBottom: 3 }}
                        />
                        {`${formatTime(props.option.working_duration)}`}
                    </CicoGapValue>
                </div>
            </div>
        </StyledOption>
    )
}
export default Option

const StyledOption = styled.div`
    color: ${colorFunctionalGray70};
    .option-primarySubtitle {
        color: ${colorFunctionalGray50};
    }
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 2px;
    width: 100%;
`

const CicoGapValue = styled.span`
    color: ${colorAttentionBlue50};
    margin-left: 10px;
`
